import {Author, Hero, ImageWithCaption, Layout, Text} from "components/BlogLayout";
import SEO                                            from "components/seo";
import React                                          from "react";
import {useArticlesData}                              from "shared/Hooks/Articles/useArticlesData";
import {ARTICLE_16}                                   from "shared/Types/articles";

const Article_16 = () => {
  const data = useArticlesData(ARTICLE_16);

  return (
    <Layout>
      <SEO title={data.SEO_title} description={data.seo} image={data.image}/>
      <Hero data={data}/>
      <Text data={data.text.first}/>
      <ImageWithCaption data={data.imagesWithCaption.first}/>
      <Text data={data.text.second}/>
      <Text data={data.text.third}/>
      <Text data={data.text.fourth}/>
      <Text data={data.text.fifth}/>
      <Text data={data.text.sixth}/>
      <Author data={data.authors}/>
    </Layout>
  );
};

export default Article_16;
